import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const news = () => {
  if (document.querySelector('.news')) {
    const swiperHero = new Swiper(".news__blocks", {
      modules: [Pagination],
      spaceBetween: 0,
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
      },
      breakpoints: {
        991: {
          slidesPerView: 3,
          spaceBetween: 12,
        },
      },

    });
  }
}