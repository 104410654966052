export const faq = () => {
  if (document.querySelector('.components__block-faq-wrapper') || document.querySelector('.template__faqs')) {
    const blocks = document.querySelectorAll('.faq__block');
    const isTemplateFaqs = document.querySelector('.template__faqs') !== null;

    blocks.forEach(block => {
      block.addEventListener("click", () => {
        const btn = block.querySelector('.faq__button');
        const panel = btn.nextElementSibling;

        if (btn.classList.contains('active')) {
          btn.classList.remove('active');
          panel.style.maxHeight = null;
        } else {
          if (!isTemplateFaqs) {
            // Close other FAQ blocks only if not in the .template__faqs context
            blocks.forEach(item => {
              const innerBtn = item.querySelector('.faq__button');
              innerBtn.classList.remove('active');
              innerBtn.nextElementSibling.style.maxHeight = null;
            });
          }
          btn.classList.add('active');
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    });
  }
}