export const header = () => {
    //==  Add hover ==//

    const getBtnAnimation = (btns) => {
        btns && btns.forEach(btn => {
            const title = btn.textContent
            const text = `<span class="label-up">${title}</span><span class="label-up">${title}</span>`
            btn.innerHTML = text
        })
    }

    const btns = document.querySelectorAll('.anim-btn')
    const footerItems = document.querySelectorAll('.footer__menu a')
    getBtnAnimation(btns)
    getBtnAnimation(footerItems)

    //==  End hover ==//
    //==  Start mode ==//
    const body = document.querySelector('body')
    const modes = document.querySelector('.header__modes')
    modes.addEventListener('click', () => {
        modes.querySelector('.header__mode-dark').classList.toggle('hidden')
        modes.querySelector('.header__mode-light').classList.toggle('active')
        body.classList.toggle('dark-mode')

        if(body.classList.contains('dark-mode')){
            document.cookie = "darkMode=1; path=/; SameSite=Lax";
        } else {
            document.cookie = "darkMode=0; path=/; SameSite=Lax";
        }
    })
    document.querySelector('.header__mode-dark').addEventListener('mouseover', () => {
        document.querySelector('#stop1-anim').beginElement();
        document.querySelector('#stop2-anim').beginElement();
    });
    //==  End mode ==//

    //== Start add burger-menu ==//
    const burgerBtn = document.querySelector('.header__burger')
    const html = document.querySelector('html')
    const header = document.querySelector('.header')
    const menuItems = document.querySelectorAll('.header__menu a')
    const burgerMenu = document.querySelector('.header__menu-wrapper')
    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            burgerMenu.classList.remove('open')
            burgerBtn.classList.remove('open')
            header.classList.remove('open')
            changeHtmlOverflow('remove')
        })
    })

    burgerBtn.addEventListener('click', () => {
        header.classList.toggle('open')
        burgerMenu.classList.toggle('open')
        burgerBtn.classList.toggle('open')

        if (burgerMenu.classList.contains('open')) {
            changeHtmlOverflow('add')
        } else {
            changeHtmlOverflow('remove')
        }
    })
    modes.addEventListener('click', () => {
        burgerMenu.classList.remove('open')
        burgerBtn.classList.remove('open')
        header.classList.remove('open')
        changeHtmlOverflow('remove')
    })
    const changeHtmlOverflow = (action) => {
        if (action === 'add') {
            html.style.overflow = 'hidden'
        }
        if (action === 'remove') {
            html.style.overflow = 'inherit'
        }
    }
    //== End add burger-menu ==//

}
