import { header } from "./modules/header.js";
import { heroSlider } from "./modules/hero-slider.js";
import { faq } from "./modules/faq.js";
import { components } from "./modules/components.js";
import { news } from "./modules/news.js";
import { blocks } from "./modules/blocks.js";
import { tabs } from "./modules/tabs.js";
import { form } from "./modules/form.js";
import { aboutSlider } from "./modules/about-slider.js";
import { post } from "./modules/post.js";
import {ourHistory} from "./modules/our-history.js";
import {ourVision} from "./modules/our-vision.js";
import {readCookie} from "./modules/read-cookie.js";

if(readCookie('darkMode') === '0' || readCookie('darkMode') === null){
    document.body.classList.remove('dark-mode');
} else {
    document.body.classList.add('dark-mode');
}

document.addEventListener("DOMContentLoaded", (event) => {
    //the scripts you need on the first screen.
    aboutSlider()

    let scriptLaunchStart = true;
    //this function is called once, after a user interaction.
    const scriptLaunch = () => {
        /*the rest of the scripts, for example:
         * working with bibilio libraries, sliders, etc.
         */

        header()
        heroSlider()
        faq()
        components()
        news()
        blocks()
        tabs()
        form()
        post()

        ourHistory();
        ourVision();

    }
    if (window.pageYOffset >= 1 && scriptLaunchStart) {
        scriptLaunchStart = false;
        scriptLaunch();
    } else if (window.pageYOffset < 1 && scriptLaunchStart) {
        const events = ['mousemove', 'click', 'mousewheel', 'scroll', 'touchstart', 'keydown', 'keypress', 'mouseover', 'mouseout', 'resize'];
        events.forEach(event => {
            window.addEventListener(event, () => {
                if (scriptLaunchStart) {
                    scriptLaunchStart = false;
                    scriptLaunch();
                }
            });
        });
    }
});