import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";
import Swiper from 'swiper';
import {
    EffectCreative, Pagination
} from "swiper/modules";

export const ourVision = () => {
    const sections = document.querySelectorAll('.our-vision');
    if (sections.length > 0) {
        gsap.registerPlugin(ScrollTrigger);
        Swiper.use([EffectCreative, Pagination]);

        const header = document.querySelector('#header');

        sections.forEach(section => {
            if(window.matchMedia("(min-width: 1025px)").matches && window.matchMedia("(min-height: 500px)").matches){
                let tl = null;
                let swiperImages = null;

                const sliderImages = section.querySelector('.our-vision__desktop__images');

                const accordionBlock = section.querySelector('.our-vision__desktop__accordion');
                const accordionItems = section.querySelectorAll('.our-vision__desktop__accordion__item');
                const accordionItemsBlock = section.querySelectorAll('.our-vision__desktop__accordion__item__block');

                if (sliderImages) {
                    swiperImages = new Swiper(sliderImages, {
                        speed: 700,
                        direction: "vertical",
                        effect: "creative",
                        creativeEffect: {
                            prev: {
                                translate: [0, "-20%", -1],
                            },
                            next: {
                                translate: [0, "100%", 0],
                            },
                        },
                        allowTouchMove: false,
                        simulateTouch: false,
                        keyboard: {
                            enabled: false,
                        },
                    });
                }

                if (swiperImages !== null) {
                    const totalSlides = swiperImages.slides.length;
                    tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: section,
                            start: "top-=12px top",
                            end: `bottom bottom-=12px`,
                            scrub: true,
                            pin: true,
                            pinSpacing: false,
                            //markers: true,
                            onUpdate: (self) => {
                                const progress = self.progress;
                                const slideIndex = Math.floor(progress * totalSlides);
                                swiperImages.slideTo(slideIndex);
                            },
                            onEnter: () => {
                                header?.classList.add('move-up');
                            },
                            onEnterBack: () => {
                                header?.classList.add('move-up');
                            },
                            onLeave: () => {
                                header?.classList.remove('move-up');
                            },
                            onLeaveBack: () => {
                                header?.classList.remove('move-up');
                            },
                        },
                    });
                }

                if (accordionItems.length > 0) {
                    const accordionItemsPreview = section.querySelectorAll('.our-vision__desktop__accordion__preview');
                    const accordionItemsPreviewWrapper = section.querySelectorAll('.our-vision__desktop__accordion__preview__wrapper');

                    let offsetTop = 0;
                    let offsetBottom = 0;
                    const accordionItemsPreviewHeights = [];

                    const accordionItemsOffsetTop = [];
                    const accordionItemsOffsetBottom = [];

                    accordionItemsPreview.forEach((item, itemIndex) => {
                        if (item) {
                            accordionItemsPreviewHeights[itemIndex] = item.getBoundingClientRect().height;
                        } else {
                            accordionItemsPreviewHeights[itemIndex] = 0;
                        }
                    });

                    const accordionItemsPreviewHeightsSum = accordionItemsPreviewHeights.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                    accordionItemsPreviewHeights.forEach((height, heightIndex) => {
                        if (heightIndex === 0) {
                            offsetBottom = accordionItemsPreviewHeightsSum;
                        } else {
                            offsetBottom -= accordionItemsPreviewHeights[heightIndex - 1];
                        }
                        if (heightIndex === 0) {
                            offsetTop = 0;
                        } else {
                            offsetTop += accordionItemsPreviewHeights[heightIndex - 1];
                        }
                        accordionItemsOffsetTop[heightIndex] = offsetTop;
                        accordionItemsOffsetBottom[heightIndex] = offsetBottom;
                    });

                    accordionItems.forEach((item, itemIndex) => {
                        const itemHeight = accordionBlock.getBoundingClientRect().height - accordionItemsPreviewHeightsSum + accordionItemsPreviewHeights[itemIndex];

                        item.style.zIndex = `${itemIndex + 1}`;
                        item.style.setProperty('--additional--padding-bottom', `${accordionItemsPreviewHeights[itemIndex]}px`);
                        item.style.setProperty('--additional--offset-bottom', `${accordionItemsOffsetBottom[itemIndex]}px`);
                        item.style.setProperty('--height', `${itemHeight}px`);

                        if (tl !== null) {
                            if (itemIndex >= 1) {
                                tl.to(item, {
                                    duration: 1,
                                    top: accordionItemsOffsetTop[itemIndex],
                                }).to(accordionItemsPreview[itemIndex], {
                                    duration: 1,
                                    top: `calc(100% - ${accordionItemsPreviewHeights[itemIndex]}px)`
                                }, '<').to(accordionItemsPreviewWrapper[itemIndex], {
                                    duration: 1,
                                    opacity: 0,
                                }, '<').to(accordionItemsPreview[itemIndex - 1], {
                                    duration: 1,
                                    top: 0
                                }, '<').to(accordionItemsPreviewWrapper[itemIndex - 1], {
                                    duration: 1,
                                    opacity: 1,
                                }, '<').to(accordionItemsBlock[itemIndex - 1], {
                                    duration: 0.35,
                                    height: 0
                                }, '<')
                            }
                        }
                    });
                }
            } else {
                const mobileSlider = section.querySelector('.our-vision__mobile__slider');
                if(mobileSlider){
                    const swiper = new Swiper(mobileSlider, {
                        slidesPerView: 1,
                        speed: 400,
                        spaceBetween: 8,
                        pagination: {
                            el: mobileSlider.querySelector(".swiper-pagination"),
                            clickable: true,
                        },
                        breakpoints: {
                            376: {
                                slidesPerView: 'auto',
                            }
                        }
                    });
                }
            }
        });
    }
}