import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';
import {getSiblings} from './get-siblings.js';

export const tabs = () => {
    //==  Start slider ==//
    if (document.querySelector(".tabs")) {
        let swipers = [];
        let swiperMob = [];

        if (window.matchMedia("(min-width: 992px)").matches) {
            const sliders = document.querySelectorAll('.tabSwiper');
            sliders.forEach((slider, index) => {
                const sliderWrapper = slider.querySelector('.swiper-wrapper');
                let sliderItems = slider.querySelectorAll('.swiper-slide');
                let slidesCount = sliderItems.length;

                if (slidesCount <= 8) {
                    let repeat = Math.ceil((8 / slidesCount) * 2);
                    if (repeat > 0) {
                        for (let i = 0, n = repeat; i < n; i++) {
                            sliderItems.forEach((item) => {
                                const swiperItemNew = document.createElement("div");
                                swiperItemNew.classList.add("swiper-slide");
                                swiperItemNew.innerHTML = item.innerHTML;

                                sliderWrapper.append(swiperItemNew);
                            });
                        }
                    }
                }

                swipers[index] = new Swiper(slider, {
                    modules: [Autoplay],
                    direction: "vertical",
                    slidesPerView: 'auto',
                    loop: true,
                    spaceBetween: 8,
                    speed: 4000,
                    allowTouchMove: false,
                    simulateTouch: false,
                    keyboard: {
                        enabled: false,
                    },

                    autoplay: {
                        pauseOnMouseEnter: false,
                        delay: 0,
                        reverseDirection: true,
                        disableOnInteraction: false,
                        enabled: false,
                    },
                });
            });
        }

        /////////////////////////

        if (window.matchMedia("(max-width: 991px)").matches) {
            const slidersMob = document.querySelectorAll('.buttonSwiper');
            slidersMob.forEach((sliderMob, index) => {

                const sliderWrapper = sliderMob.querySelector('.swiper-wrapper');
                let sliderItems = sliderMob.querySelectorAll('.swiper-slide');
                let slidesCount = sliderItems.length;

                if (slidesCount <= 8) {
                    let repeat = Math.ceil((8 / slidesCount) * 2);
                    if (repeat > 0) {
                        for (let i = 0, n = repeat; i < n; i++) {
                            sliderItems.forEach((item) => {
                                const swiperItemNew = document.createElement("div");
                                swiperItemNew.classList.add("swiper-slide");
                                swiperItemNew.innerHTML = item.innerHTML;

                                sliderWrapper.append(swiperItemNew);
                            });
                        }
                    }
                }
                swiperMob[index] = new Swiper(sliderMob, {
                    modules: [Autoplay],
                    slidesPerView: 'auto',
                    direction: "vertical",
                    loop: true,
                    spaceBetween: 4,
                    speed: 3000,
                    allowTouchMove: false,
                    simulateTouch: false,
                    keyboard: {
                        enabled: false,
                    },
                    autoplay: {
                        pauseOnMouseEnter: false,
                        delay: 0,
                        reverseDirection: true,
                        disableOnInteraction: false,
                        enabled: false,
                    },
                });
            });
        }

        /////////////////////////

        const tabsBodysWrapper = document.querySelectorAll('.tabSwiper-wrapper');
        const tabButtons = document.querySelectorAll('.tabs__button');
        const tabsBodys = document.querySelectorAll('.tabSwiper');
        let currentIndex = 0;
        let interval = null;

        tabButtons.forEach((tabButton, index) => {
            tabButton.addEventListener('click', () => {
                if (!tabButton.classList.contains('_tab-active')) {
                    if (interval !== null) {
                        clearInterval(interval)
                    }
                    tabButton.classList.add('_tab-active');

                    getSiblings(tabButton).forEach(item => {
                        item.classList.remove('_tab-active');
                    });

                    if (tabsBodysWrapper[index]) {
                        tabsBodysWrapper[index].classList.add('active');
                        getSiblings(tabsBodysWrapper[index]).forEach(item => {
                            item.classList.remove('active');
                        });
                    }


                    swipers.forEach((swiper, swiperIndex) => {
                      if (index === swiperIndex) {
                        swiper.autoplay.start();
                      }
                      else {
                        swiper.autoplay.stop();
                      }
                    })
                    swiperMob.forEach((swiper, swiperIndex) => {
                      if (index === swiperIndex) {
                        swiper.autoplay.start();
                      }
                      else {
                        swiper.autoplay.stop();
                      }
                    })

                    if (window.innerWidth <= 991) {
                        if (interval !== null) {
                            clearInterval(interval)
                        }
                    } else {
                        interval = setInterval(() => {
                            if (tabButtons[index + 1]) {
                                tabButtons[index + 1].click()
                            } else {
                                tabButtons[0].click()
                            }

                        }, 10000);
                    }
                }
            })
        })

        tabButtons[0].click()
    }

};