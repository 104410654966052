import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";

export const blocks = () => {
  if (document.querySelector('.blocks')) {
    const swiperHero = new Swiper(".blocks__wrapper", {
      modules: [Pagination],
      spaceBetween: 12,
      slidesPerView: 1.15,
      centeredSlides: true,
      slidesPerColumn: 1,
      pagination: {
        el: ".swiper-pagination",
      },
      breakpoints: {
        1023: {
          slidesPerView: 3,
          slidesPerColumn: 3,
          spaceBetween: 0,
          centeredSlides: false,
        },
        1439: {
          slidesPerView: 4,
          slidesPerColumn: 2,
          spaceBetween: 0,
          centeredSlides: false,
        },
      },

    });
  }
}