import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger.js";
import Swiper from 'swiper';
import {
    EffectFade,
    Controller,
    Grid,
    EffectCreative
} from "swiper/modules";
import {getSiblings} from "./get-siblings.js";

export const ourHistory = () => {
    const sections = document.querySelectorAll('.our-history');
    if (sections.length > 0) {
        gsap.registerPlugin(ScrollTrigger);
        Swiper.use([EffectFade, Controller, Grid, EffectCreative]);

        const header = document.querySelector('#header');

        sections.forEach(section => {
            let swiperYear = null;
            let swiperDescription = null;
            let swiperImages = null;
            let oneOfTheSwiper = null;
            let tl = null;

            const sliderYear = section.querySelector('.our-history__desktop__timeline-block__slider-year');
            const sliderDescription = section.querySelector('.our-history__desktop__timeline-block__slider-description');
            const sliderImages = section.querySelector('.our-history__desktop__images');
            const yearsEls = section.querySelectorAll('.our-history__desktop__timeline-block__years li');

            if(window.matchMedia("(min-width: 1025px)").matches && window.matchMedia("(min-height: 500px)").matches){
                if (sliderYear) {
                    oneOfTheSwiper = swiperYear = new Swiper(sliderYear, {
                        speed: 250,
                        direction: "vertical",
                        allowTouchMove: false,
                        simulateTouch: false,
                        keyboard: {
                            enabled: false,
                        },
                    });
                }
                if (sliderDescription) {
                    oneOfTheSwiper = swiperDescription = new Swiper(sliderDescription, {
                        speed: 400,
                        slidesPerView: 1,
                        spaceBetween: 0,
                        effect: "fade",
                        fadeEffect: {
                            crossFade: true,
                        },
                        allowTouchMove: false,
                        simulateTouch: false,
                        keyboard: {
                            enabled: false,
                        },
                    });
                }
                if (sliderImages) {
                    swiperImages = new Swiper(sliderImages, {
                        speed: 700,
                        direction: "vertical",
                        effect: "creative",
                        creativeEffect: {
                            prev: {
                                translate: [0, "-20%", -1],
                            },
                            next: {
                                translate: [0, "100%", 0],
                            },
                        },
                        allowTouchMove: false,
                        simulateTouch: false,
                        keyboard: {
                            enabled: false,
                        },
                    });
                }

                if (swiperYear !== null && swiperDescription !== null) {
                    swiperYear.controller.control = swiperDescription;
                    swiperDescription.controller.control = swiperYear;
                }

                if (oneOfTheSwiper !== null && yearsEls.length > 0) {
                    const totalSlides = oneOfTheSwiper.slides.length;
                    tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: section,
                            start: "top-=12px top",
                            end: `bottom bottom-=12px`,
                            scrub: true,
                            pin: true,
                            pinSpacing: false,
                            //markers: true,
                            onUpdate: (self) => {
                                const progress = self.progress;
                                const slideIndex = Math.floor(progress * totalSlides);
                                oneOfTheSwiper.slideTo(slideIndex);
                                if (swiperImages !== null) swiperImages.slideTo(slideIndex);

                                const yearsEl = yearsEls[slideIndex];
                                if (yearsEl) {
                                    yearsEl.classList.add('active');
                                    getSiblings(yearsEl).forEach(sib => {
                                        sib.classList.remove('active');
                                    });
                                }
                            },
                            onEnter: () => {
                                header?.classList.add('move-up');
                            },
                            onEnterBack: () => {
                                header?.classList.add('move-up');
                            },
                            onLeave: () => {
                                header?.classList.remove('move-up');
                            },
                            onLeaveBack: () => {
                                header?.classList.remove('move-up');
                            },
                        },
                    });
                }
            }
        });
    }
}